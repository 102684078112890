import {
  FACT_FILE_CHARACTERS_AND_PETS,
  FACT_FILE_CREATURES,
  FACT_FILE_INDEX,
  FACT_FILE_LOCATIONS,
  FACT_FILE_MAGICAL_MISCELLANY,
  FACT_FILE_OBJECTS,
  FACT_FILE_POTIONS_AND_PLANTS,
  FACT_FILE_SPELLS,
} from '@/src/constants/routes';

// Auth Flows
export const AUTH_BACK_BTN_REDIRECT = 'authBackBtnRedirect';
export const AUTH_SUCCESS_REDIRECT_PATH = 'authSuccessRedirectPath';
export const AUTH_SUCCESS_DEFAULT_EXPIRATION = 365 * 60 * 60 * 24;

export const RESET_ANALYTICS = 'resetAnalytics';
export const DO_NOT_SELL_OR_SHARE_FLAG = 'userDoNotSellOrShare';
export const DO_NOT_SELL_OR_SHARE_EVENT = 'DoNotSellOrShareUpdated';

export const IS_REGISTERED_FLAG = 'is_registered';
export const IS_UNDERAGE_FLAG = 'is_underage';

export const HIDE_FROM_WEB_TAG = 'hide-from-web';

export const SIGNUP_NOTIFICATION_SEEN = 'signupNotificationSeen';
export const POTTERMORE_DOMAIN = 'pottermore.com';
export const numOfItemPerPage = 9;
export const PROFILE_FIRSTLOAD_HOUSE = 'firstLoadHouse';
export const PROFILE_FIRSTLOAD_PATRONUS = 'firstLoadPatronus';
export const PROFILE_FIRSTLOAD_WAND = 'firstLoadWand';
export const REDIRECT_BANNER = 'redirect-banner';
export const UNLINK_RENDEZVOUS_INITIATED = 'unlink-rendezvous-initiated';
export const LAST_QUIZ_IN_EVENT_IN_PROGRESS = 'last-quiz-in-progress';
export const RESYNC_RENDEZVOUS_INITIATED = 'resync-rendezvous-initiated';
export const COLLECTION_THEME_COLORS = {
  Gryffindor: {
    colorA: '#591E1C',
    colorB: '#6A2B2B',
  },
  Hufflepuff: {
    colorA: '#B17220',
    colorB: '#A46A1E',
  },
  Ravenclaw: {
    colorA: '#173151',
    colorB: '#1B3A62',
  },
  Slytherin: {
    colorA: '#05201C',
    colorB: '#07312A',
  },
  Dark: {
    colorA: '#10141b',
    colorB: '#242633',
  },
  Medium: {
    colorA: '#24152F',
    colorB: '#20011e',
  },
  Light: {
    colorA: '#6b63b5',
    colorB: '#f9f4f2',
  },
};
export const HOUSE_GAMBIT_COLOR_MAPPING = {
  gryffindor: 'red',
  slytherin: 'green',
  hufflepuff: 'yellow',
  ravenclaw: 'blue',
};
export const HOGWARTS_HOUSES = ['gryffindor', 'ravenclaw', 'hufflepuff', 'slytherin'];

export const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
};

export const getQuizEventLocalStorageId = () => {
  return `${window.location.pathname}${LAST_QUIZ_IN_EVENT_IN_PROGRESS}`;
};

export const UTM_CAMPAIGN_COOKIE = 'utm_campaign';

// Contentful Configurations
// These values should map directly to the external id of a configuration
// in contentful.
export const NEWSLETTER_OPT_IN_PERKS_CONFIGURATION = 'newsletter-opt-in-perks-configuration';
export const ITEM_UNLOCK_PROMOS_CONFIGURATION = 'item-unlock-promos-configuration';
export const FEATURED_FACT_FILE_CONFIGURATION = 'featured-fact-file-configuration';
export const QUIDDITCH_CHAMPIONS_CONNECT_CONFIGURATION =
  'quidditch-champions-connect-configuration';

export const ITEM_UNLOCK_SECRET_WORD_LOCALSTORAGE_KEY = 'secret-word';
export const ITEM_UNLOCK_UNLOCKED_ITEMS_LIST_KEY = 'pm-i-list';

// Shopify cart localstorage key
export const SHOPIFY_CART_LOCALSTORAGE_KEY = '.checkoutId';

export const POLL_ANSWER_LOCALSTORAGE_KEY = 'poll-answer';

export const QUIZ_DIFFICULTY_LEVEL = {
  easy: 1,
  medium: 2,
  difficult: 3,
};

export const QUIZ_SLICK_TIMEOUT = 150;

export const HOMEPAGE_COMPONENT_TYPES = {
  PRIMARY_HERO: 'primaryHero',
  MULTI_TILE_PROMO: 'contentList',
  FLEXIBLE_CONTAINER: 'flexibleContainer',
  FEATURED_QUIZ_LIST: 'featuredQuizList',
  USER_IDENTITY_CHECKLIST: 'userIdentityChecklist',
  FLEXIBLE_CAROUSEL: 'flexibleCarousel',
  FULL_WIDTH_PROMO: 'fullWidthPromo',
  SHOP_ROW: 'shopRow',
  POLL: 'poll',
  FLEXIBLE_IMAGE_CTA: 'flexibleContainerImageCTA',
  GEOTARGETED_CONTENT: 'geotargetedHomepageContent',
  COUNTDOWN: 'countdown',
};

export const FILTER_CATEGORIES_DATA = [
  { name: 'All', slug: null, path: FACT_FILE_INDEX },
  { name: 'Characters & Pets', slug: 'characters-and-pets', path: FACT_FILE_CHARACTERS_AND_PETS },
  { name: 'Objects', slug: 'objects', path: FACT_FILE_OBJECTS },
  { name: 'Locations', slug: 'locations', path: FACT_FILE_LOCATIONS },
  { name: 'Plants & Potions', slug: 'plants-and-potions', path: FACT_FILE_POTIONS_AND_PLANTS },
  { name: 'Creatures', slug: 'creatures', path: FACT_FILE_CREATURES },
  { name: 'Spells', slug: 'spells', path: FACT_FILE_SPELLS },
  { name: 'Magical Miscellany', slug: 'magical-miscellany', path: FACT_FILE_MAGICAL_MISCELLANY },
];

export const AGE_GATE_IS_UNDERAGE = 'age-gate-is-underage';
